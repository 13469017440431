import { createContext,useState } from 'react';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import { Home,Address } from './views';
import { Header,Footer,Modal } from './components';
import { useModal } from './hooks';
import { ModalContextProvider } from './context';
import './App.css';
import './css/miniCss.css';
import './css/stylesheet.css';
import './css/table.css';



export const AppContext = createContext<unknown>({});

function App() {
  const [allScroll,setAllScroll] = useState<any>({});
  const [showModal,setShowModal] = useState(false);
  const [modalContent,setModalContent] = useState(1);
  const handleScroll = (arg:()=>{}) =>{
      arg();
  }
  const perFormScroll = (arg:any) =>{
    if(arg && arg.current){
        arg.current.scrollIntoView({behavior: "smooth"});
    }
  }

  return (
    <ModalContextProvider>
      <div id="mainContainer" className="col-12">
      <AppContext.Provider value={{handleScroll,perFormScroll,allScroll,setAllScroll,showModal,setShowModal,modalContent,setModalContent}}>
    
          <Header />
          <Routes>
            <Route path='/' element={<Home />}/>
            <Route path="/address" element={<Address />}/>
          </Routes>
          <Footer />

        <Modal />
      </AppContext.Provider>
      
    </div>
  </ModalContextProvider>
  );
}

export default App;
