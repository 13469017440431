import React from 'react'
import styled from 'styled-components';
import { Button } from '../components';
import img from '../images/address2.png';

const Address = () => {
  const addressArr = [
    '0xbC6eA65E70d0d598aD7C405b18598E25B5e68d7E',
  ]
  const copyAddress = (ind:number) =>{
    let btn = document.querySelectorAll('button')[ind];
    let address = addressArr[ind];
    navigator.clipboard.writeText(address);
    btn.innerHTML = 'Address Copied';
    btn.disabled = true;
    setTimeout(() => {
        btn.innerHTML = 'Copy Address';
        btn.disabled = false;
    }, 3000);
}
  return (
    <Wrapper>
      <div>
        <img src={img} alt="address" />
        <p>Send only Linear Token ( ERC20 / BEP20 ) to the address</p>
        <p>
          0xbC6eA65E70d0d598aD7C405b18598E25B5e68d7E
        </p>
        <div>
          <Button onClick={()=>copyAddress(0)}>
            Copy Address
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default Address;


const Wrapper = styled.div`
  width:100%;
  display:flex;
  justify-content: center;
  /* align-items:center; */
  padding:40px 0;
  min-height: 100vh;

  & > div{
    width:min(500px,80%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px;
    gap:10px;
    overflow: hidden;
  }

  & > div > img{
    width:250px;
    border: 2px solid var(--blue-color);
    border-radius: 5px;
  }

  & > div > p{
    margin:0;
    text-align:center;
  }


`