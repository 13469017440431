import {createContext,useState} from 'react';
import styled from 'styled-components';

export const ModalContext = createContext<any>({});

export const ModalContextProvider = (props:any) =>{
    const [modalContent,setModalContent] = useState<any>('');
    const [showModal,setShowModal] = useState<boolean>(false);
    const children = props.children;
    const value = {
        modalContent,
        setModalContent,
        showModal,
        setShowModal
    }
    return <ModalContext.Provider value={value}>
        {children}
        {showModal ? 
        <Wrapper>
            <div>
                {modalContent}
            </div>
        </Wrapper>
        :''}
    </ModalContext.Provider>
}

const Wrapper = styled.aside`
    width:100%;
    height: 100%;
    z-index: 1000000;
    position:fixed;
    background-color: rgba(2,5,35,0.8);
    display: flex;
    justify-content: center;
    /* align-items: center; */
    top:0;
    left:0;
    & > div{
        display: flex;
        justify-content: center;
        /* align-items: center; */
        height:fit-content;
        padding:20px;
    }
    & > div  img{
        width:60px;
    }

`