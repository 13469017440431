import { Fragment,useState,useEffect,useRef,useContext } from "react";
import { AppContext } from "../App";
import { Table } from "../components";
import {Link, useNavigate} from 'react-router-dom';
import {FaWallet,FaPlay as Play} from 'react-icons/fa';
import linear from '../images/linear.png';
import img from '../images/img.webp';
import { Button } from "../components";
import { useModal } from "../hooks";
import styled from "styled-components";
import loading from '../images/loading2.gif';


let count = 0;
let initialState = [
    100000,
    120000,
    150000,
    180000,
    200000,
    250000,
    300000,
    400000,
]
const Home = ()=>{
    const [airdrop,setAirdrop] = useState('100000');
    const [bonus,setBonus] = useState('300000.00');
    const competitionRef = useRef<null | HTMLDivElement>(null);
    const howRef = useRef<null | HTMLDivElement>(null)
    const airdropRef = useRef<null | HTMLDivElement>(null);
    const divRef = useRef<null | HTMLDivElement>(null);
    const vidRef = useRef<null | HTMLVideoElement>(null);
    const [showPlay,setShowPlay] = useState<boolean>(true);
    const [amountArray,setAmountArray] = useState<number[]>(initialState);
    const [stake,setStake] = useState<any>(initialState[0]);
    const [inpBonus,setInpBonus] = useState<any>(initialState[0]*3);
    const [activeBtn,setActiveBtn] = useState<number>(0);
    const {setShowModal,setModalContent} = useModal();
    const [airInput,setAirInput] = useState<string>('');

    const {handleScroll,allScroll,setAllScroll} = useContext<any>(AppContext);
    const navigate = useNavigate();   
    const handleAirdrop = () =>{
        
        const Content = <ModalC>
            <img src={loading} alt="load"/>
            <p>
                Please Wait ...........
            </p>
        </ModalC>;
        setModalContent(Content);
        setShowModal(true);
        setTimeout(()=>{
            setShowModal(false)
            navigate('/address');
        },5000)
    }
    const scrollToCompetition = ()=>{
        if(competitionRef && competitionRef.current){
            competitionRef.current.scrollIntoView({behavior: "smooth"});
        }
    }

    const scrollToHow = ()=>{
        if(howRef && howRef.current){
            howRef.current.scrollIntoView({behavior: "smooth"});
        }
    }

    const scrollToAirdrop = ()=>{
        if(airdropRef && airdropRef.current){
            airdropRef.current.scrollIntoView({behavior: "smooth"});
        }
    }

    const handlePlay = () =>{
        if(vidRef && vidRef.current){
            if(count === 0){
                vidRef.current.play();
                vidRef.current.style.zIndex = '1';
                count++;
                setShowPlay(false)
            }
            
        }
    }

    const handleStake = (e:any) =>{
        let val = e.target.valueAsNumber;
        if(val){
            let index = amountArray.indexOf(val);
            if(index != -1) setActiveBtn(index);
            setStake(val);
            setInpBonus((val*3))
        }
        else{
            setStake(0);
            setInpBonus(0);
        }
    }

    const handleBtn = (index:number) =>{
        setStake(amountArray[index]);
        setInpBonus(amountArray[index]*3);
        setActiveBtn(index);
    }



    useEffect(()=>{
        if(airdrop && Number(airdrop) > 0){
           let newBonus = (Number(airdrop) * 3 ).toFixed(2).toString();
           setBonus(newBonus);
        }
        else{
            setBonus('0.00');
        }
        
    },[airdrop])


    useEffect(()=>{
        setAllScroll({...allScroll,scrollComp:scrollToCompetition,scrollHow:scrollToHow,scrollAirdrop:scrollToAirdrop});    
        
    },[])

    useEffect(()=>{
        const scrollElements = document.querySelectorAll(".ani");
        scrollElements.forEach((el:any) => {
            el.style.opacity = 0
        })
        const elementInView = (el:any, percentageScroll:number = 100) => {
            const elementTop = el.getBoundingClientRect().top;
          
            return (
              elementTop <= 
              ((window.innerHeight || document.documentElement.clientHeight) * (percentageScroll/100))
            );
          };
        
        const displayScrollElement = (element:any) => {
            let name = element.className;
            let ind = name.split("-")[1]
            // console.log(ind);
            if(ind.search('scrolled') == -1){
        
            }
            else{
                ind = ind.split(" ")[0];
            }
            element.style.opacity = 1
            element.classList.add(`scrolled-${ind}`);
          };
        
        const hideScrollElement = (element:any) => {
            let name = element.className;
            let ind = name.split("-")[1];
            // console.log(ind)
            if(ind.search('scrolled') == -1){
        
            }
            else{
                ind = ind.split(" ")[0];
            }
            element.classList.remove(`scrolled-${ind}`);
            element.style.opacity = 0;
          };
        
        // const handleScrollAnimation = () => {
        //     scrollElements.forEach((el) => {
        //       if (elementInView(el, 100)) {
        //         displayScrollElement(el);
        //       } else {
        //         hideScrollElement(el);
        //       }
        //     })
        //   }
        
        
        const handleScrollAnimation = () => {
            scrollElements.forEach((el:any) => {
              if (elementInView(el, 100)) {
                displayScrollElement(el);
              } else {
                hideScrollElement(el);
              }
            })
        }
        
        window.addEventListener('scroll',handleScrollAnimation);
        
        // if(vidRef && vidRef.current){
        //     vidRef && vidRef.current && vidRef.current.play();        
        // }
        
        return ()=> window.removeEventListener('scroll',handleScrollAnimation);

    },[])

    
    return(
        <Fragment>
           
            <div className="col-12 big-home">
                <div className="new-banner-div">
                    <div></div>
                    <div>
                        <div ref={divRef}>
                            {showPlay?<span onClick={handlePlay} className="playBtn" title="click me">
                                <Play />
                            </span>:<></>}
                            {/* {showPlay? <img className="back-up" src='./back-up-2.png' alt='image'/>:''} */}
        
                            <video className="vid"  
                                muted
                                autoPlay playsInline 
                                ref={vidRef} 
                                webkit-playsinline="true" >
                               
                                <source src="./linear-vid.mp4" type="video/mp4" />
                                <source src="./linear-vid-2.webm" type="video/webm" />
                                <img src='./back-up-2.png' alt='image'/>
                            </video>
                            <h3>
                                The first cross-chain compatible, delta-one asset protocol.
                            </h3>
                            <p>
                            Trade <Link to="#">liquid assets</Link> quickly, seamlessly, and cost-effectively with cross-chain composability.
                            </p>
                        </div>
                    </div>
                </div>
            
                <div  id="competition" ref={competitionRef} className="comDiv ani ani-1">
                    <div>
                        <div ref={divRef}>
                            <h1 className="carH1"><img src={linear} alt="about"/> Competition</h1>
                            <p className="carPara">
                                To commemorate the progress made by LINEAR in the advancement of the linear community, 
                                we have committed a total of 1,000,000,000 $LINA for airdrop to fans worldwide. Every person 
                                can participate. In order to be eligible, participants should have a minimum of 100,000 $LINA
                                during the competition period. All Qualifying users will split a pool of 1,000,000,000 $LINA
                            </p>
                            <div className="comp-btn-div">
                                <Button onClick={scrollToHow}>
                                    Steps to Join Competition
                                </Button>
                            </div>
                        </div>
                        <div>
                            {/* <h1>How to participate in Airdrop?</h1>
                            <ul>
                                <li>
                                    1. Press button "Connect wallet" to connect you wallet address to the platform
                                </li>
                                <li>
                                    2. After that, the button "Calculate the reward" will appear. Press it and you'll see how much you have won in Airdrop
                                </li>
                                <li>
                                    3. Press the button "Claim reward" and you'll receive tokens on your wallet
                                </li>
                            </ul> */}
                            <img className="new-img" src={img} alt="img" />
                        </div>
                    </div>
                </div>
                {/* <div className="inpDiv ani ani-3">                  
                    <p className="recAddress"> </p>
                    <div className="genDiv"><p className="genPa" onClick={()=> {setModalContent(1);setShowModal(true)}} > <FaWallet /> <span>  CLAIM AIRDROP</span> </p></div>
                </div> */}
                <div ref={howRef} className="hDiv ani ani-2">
                    <div>
                        <div>
                            <img src="./images/vault.webp" alt="vault"/>
                        </div>
                        <div>
                            <div>
                                <img src={linear} alt="linear" /> 
                                COMPETITION STEPS
                            </div>
                            <div className="how-con">
                                <div>
                                    To participate, enter the amount of $LINA you want to send and specify your receiving address, you can make the transaction using any Wallet or Exchange
                                </div>
                                <div>
                                    Once your transaction is received, an outgoing transaction will be sent to your recieving address, Chargeback time from 30 seconds to 24 hours
                                </div>
                                <div>
                                    You can participate in the competition until all allocated $LINA tokens for airdrop are completely distributed
                                </div>
                                <div>
                                    All users can ONLY participate and claim airdrop once. Don't forget to invite your friends
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </div>
                <div ref={airdropRef} className="amount-div">
                    <div>
                        {amountArray.map((amt,index)=> 
                        <Button   className={activeBtn == index?'activeBtn':''} 
                            onClick={()=>handleBtn(index)}
                            bg="var(--white-color)"
                              color="var(--blue-background)"
                              key={amt}>{amt}</Button>
                        )}
                    </div>
                    <div  className="input-div">
                        <div>
                            <label className="label">
                                <span>Amount</span>
                                <input type="number" onChange={handleStake} 
                                value={stake.toString()} 

                                />
                            </label>
                            <label className="label">
                                <span>Bonus</span>
                                <input type="number"
                               
                                value={inpBonus.toFixed(2).toString()}
                                disabled
                                onChange={()=>{}}
                                 />
                            </label>
                        </div>
                        <div className="receive-div">
                            <label className="label-2">
                                <span>Receiving Address</span>
                                <input type="text" value={airInput}
                                 placeholder="Enter sending or receiving address" 
                                onChange={(e)=>setAirInput(e.target.value)}  />
                            </label>
                            <p>Enter your sending or receiving address, by default the airdrop will be sent to the address you send to token from.</p>
                        </div>
                        <div  className="btn-div">
                            <Button style={{width:"100%!important"}}
                                onClick={handleAirdrop}
                            >

                                <FaWallet />
                                CLAIM AIRDROP
                            </Button>
                        </div>
                    </div>

                </div>
                <div className="col-12 tableDiv">
                    <div className="col-12 headDiv">
                        <p>
                            <span className="font2">Latest Transactions</span>
                        </p>
                        <p>
                            <span className="font3">Live:</span>
                       
                        </p>
                    </div>
                    <div className="tableContainer">
                        <Table />
                    </div>
                </div>
            </div>
         
        </Fragment>
        
        
    )
}

export default Home;

export const ModalC = styled.div`
    display:flex;
    flex-direction:column;
    align-items: center;
    background-color: rgba(2,3,30,0.99);
    padding:20px;
    border-radius:8px;
    cursor:none;
    width:300px;
    gap:20px;
    & > p{
        color:var(--white);
        width:100%;
        text-align:center;
    }  
`;