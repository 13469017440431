import React from 'react'
import styled from 'styled-components';

export const Button = (props:any) => {
    const children = props.children;
  return (
    <button {...props}>{children ?children:'Button'}</button>
  )
}

export const  StyledButton = styled(Button)`
    display:flex;
    padding:12px 35px;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap:10px;
    border-radius: 20px;
    outline:none;
    border:1px solid var(--blue-background);
    color:${props => props.color?props.color:'var(--white-color)'};
    background-color: ${props => props.bg?props.bg:'var(--blue-background)'};
    cursor:pointer;
    position:relative;
    &:hover{
        transform: scale(1.045);
        box-shadow:0 0 3px 2px rgba(0,0,0,0.1);
    }

`;

