const Footer = (e:any)=>{
    return (
        <div className="col-12 footer">
            <div>
                <span className="footSpan">
                    &copy; 2024 Linear Finance. All rights reserved.
                </span>
            </div>
        </div>
    )
}


export default Footer;