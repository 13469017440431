import { Fragment,useEffect} from "react";

const txHash = ["eC2eC23f47Aee33a...","58c621D2E987Efeb...","eC23f56Aee33ba9e...","3f5aAff33ba9eCb5...",
    "45ee33ba9eCb5B6...","9eCb5B6CB458c621...","Cb5B6CB458c621D2...","7da4r86dca4readc...","46faecdba7Aee33a...",
    "4ac7EfebE989703b...","4858fbce58e857c5...","5dfae06Aee33ba9e...","5acf689b3ba9eCb5...","ba9eCb5B69eCb5B6...",
    "76ebLINAacccB4521...","F4672dB458c621D2...","efbc4567da4readc..","5FDaebc247Aee33a...","679b41D2E987Ef93...",
    "7FDAfLINAbf33ba9e...","54eaBA833ba9eCb5...","97eacbfde9eCb5B6...","ei4e5B686dAEFB95...","64dcfaEBA2358c60.."
            
];

const reAddress = ["0x8737b271cb5CBAd5C...","0x8737b271cb5CBAd5C...","0x37d5B6CB458c621D2E9...","0x37d5B6CB458c621D2E9...",
    "0x7ab47483bcf7378dge3....","0x7ab47483bcf7378dge3....","0x93dfe663736bafe37...","0x93dfe663736bafe37...",
    "0x46fea368bc83739ce373...","0x46fea368bc83739ce373...","0x47474fceac83b3737ed...","0x47474fceac83b3737ed...",
    "0x73ebc73738cdd8474ff93...","0x73ebc73738cdd8474ff93...","0x996fcbdebbed74748932...","0x996fcbdebbed74748932...",
    "0x8237bbecfa7bcdd773fca00..","0x8237bbecfa7bcdd773fca00..","0x71274edfcaeeaab3849...","0x71274edfcaeeaab3849...",
    "0x1473feebb83732aacecb4...","0x1473feebb83732aacecb4...","0x747fbcaade38344bdcaed...","0x747fbcaade38344bdcaed...",
     

];

const amount = [878247.39,2634742.17,314129.123,942837.37,483225.116,1449675.35,400841.31,1202523.93,329135.61,988135.84,
    345243.96,1035731.89,275108.76,825326.29,1075042.35,3225127.05,1042542.14,3127626.43,365479.07,1096437.21,308805.49,
    926416.49,514543.07,1549629.21];

var myAddress = ["0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300...",
    "0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300...",
    "0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300...",
    "0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300...",
    "0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300...",
    "0x73f6854aC17e300daC784...","0x73f6854aC17e300...","0x73f6854aC17e300daC784...","0x73f6854aC17e300..."];
     

let chkInt = 0;
let rowCnt = 0;
let dataCnt = 0;
let myInt = 0;
let start:any = false;





const TableRow = (e:any) =>{
    function addtoTable(){
        //alert("test");
        let newRow = document.createElement("tr");
        newRow.setAttribute("class","tr trBack");
        if(dataCnt == 24){
            dataCnt = 0;
        }
        if(rowCnt == 0){
            rowCnt++;
            let td1 = document.createElement("td")
            let txt1 = document.createTextNode(txHash[dataCnt]);
            td1.appendChild(txt1);
            let td2 = document.createElement("td");
            let span2A = document.createElement("span");
            span2A.setAttribute("class","mRight")
            let txt2A = document.createTextNode("now");
            span2A.appendChild(txt2A);
            let span2B = document.createElement("span");
            span2B.setAttribute("class","mRight")

            let span2C = document.createElement('span');
            span2C.setAttribute("class","mRight")
            td2 .appendChild(span2A);td2.appendChild(span2B);td2.appendChild(span2C);
            let td3 = document.createElement("td");
            td3.setAttribute("class","tClass");
            let txt3 = document.createTextNode(reAddress[dataCnt]);
            let newSpan = document.createElement("span");
            newSpan.setAttribute("class","red");
            let newTxt = document.createTextNode("claimed");
            newSpan.appendChild(newTxt);
            td3.appendChild(txt3);td3.appendChild(newSpan)
            let td4 = document.createElement("td");
            let txt4 = document.createTextNode(myAddress[dataCnt]);
            td4.appendChild(txt4);
            let td5 = document.createElement("td");
            let txt5 = document.createTextNode(amount[dataCnt]+" LINA");
            td5.appendChild(txt5);
            newRow.appendChild(td1);newRow.appendChild(td2);newRow.appendChild(td3);newRow.appendChild(td4);
            newRow.appendChild(td5);
        
        }
        else{
            rowCnt--;
            let td1 = document.createElement("td")
            let txt1 = document.createTextNode(txHash[dataCnt]);
            td1.appendChild(txt1);
            let td2 = document.createElement("td");
            let span2A = document.createElement("span");
            span2A.setAttribute("class","mRight")
            let txt2A = document.createTextNode("now");
            span2A.appendChild(txt2A);
            let span2B = document.createElement("span");
            span2B.setAttribute("class","mRight")
            let span2C = document.createElement('span');
            span2C.setAttribute("class","mRight")
            td2 .appendChild(span2A);td2.appendChild(span2B);td2.appendChild(span2C);
            let td3 = document.createElement("td");
            td3.setAttribute("class","tClass");
            let txt3 = document.createTextNode(myAddress[dataCnt]);
            let newSpan = document.createElement("span");
            newSpan.setAttribute("class","green");
            let newTxt = document.createTextNode("claimed");
            newSpan.appendChild(newTxt);
            td3.appendChild(txt3);td3.appendChild(newSpan)
            let td4 = document.createElement("td");
            let txt4 = document.createTextNode(reAddress[dataCnt]);
            td4.appendChild(txt4);
            let td5 = document.createElement("td");
            let txt5 = document.createTextNode(amount[dataCnt]+ " LINA");
            td5.appendChild(txt5);
            newRow.appendChild(td1);newRow.appendChild(td2);newRow.appendChild(td3);newRow.appendChild(td4);
            newRow.appendChild(td5);
    
            
        }
        //newRow.appendChild(td1);newRow.appendChild(td2);newRow.appendChild(td3);newRow.appendChild(td4);
        //newRow.appendChild(td5);
        let parent:any = document.querySelector(".txTable > tbody");
        let child:any = document.querySelector(".headerRow + .tr");
        if(myInt == 0){
            increMin();
            myInt++;
        }

        else if(myInt == 1){
            //newWidth();
            myInt = 0;
        }
        
        parent.insertBefore(newRow,child);
        setTimeout(rmBack,499)
        dataCnt++;
        //console.log(dataCnt)

    }

    function increMin(){
        let rowLen = document.querySelectorAll(".txTable tr").length;
        let i;
        for (i = 2;i < rowLen+1 ;i++){
            let oldNum = document.querySelector<any>(".txTable > tbody > tr:nth-child("+i+") > td:nth-child(2) > span:first-child").innerHTML;
            if(oldNum.localeCompare("now") == 0){
                document.querySelector<any>(".txTable > tbody > tr:nth-child("+i+") > td:nth-child(2) > span:first-child").innerHTML = `${getRnd()}`;
                document.querySelector<any>(".txTable > tbody > tr:nth-child("+i+") > td:nth-child(2) > span:nth-child(2)").innerHTML = "min";
                document.querySelector<any>(".txTable > tbody > tr:nth-child("+i+") > td:nth-child(2) > span:nth-child(3)").innerHTML = "ago";
        
            }
            else{
                let newNum = Number(oldNum);
                let nNum = newNum+getRnd();
                document.querySelector<any>(".txTable > tbody > tr:nth-child("+i+") > td:nth-child(2) > span:first-child").innerHTML = nNum;
                //alert(nNum);

            }

        }
    }

    function rmBack(){
        document.querySelector<any>(".trBack").classList.remove("trBack");
    }

    function getRnd():number { 
        let min = 3;
        let max = 4;
        let dat = Math.floor(Math.random() * (max - min + 1)) + min; 
        return dat;

    }
    const loadData = ()=>{
        start = setInterval(addtoTable,4999);
    }
    useEffect(()=>{
       if(!start){
         loadData();
       }
        
    },[])
    return (
        <Fragment>
            <table className="font3 txTable">
                <tbody>
                    <tr className="headerRow"><td>TxHash</td><td>Age</td><td>From</td><td>To</td><td>Quantity</td></tr>
                    <tr className="tr"><td>64dcfaEBA2358c60...</td><td><span>now</span>&nbsp;<span></span>&nbsp;<span></span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x42848aDcac78490cEAAfea...</td><td>871234.48 LINA</td></tr>
                    <tr className="tr"><td>5ADe5B686dAEFB95...</td><td><span>now</span>&nbsp;<span></span>&nbsp;<span></span></td><td className="tClass">0x42848aDcac78490cEAAfea...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>290411.48 LINA</td></tr>
                    <tr className="tr"><td>97eacbfde9eCb5B6...</td><td><span>3</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>1289824.75 LINA</td></tr>
                    <tr className="tr"><td>54eaBA833ba9eCb5...</td><td><span>5</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>429941.58 LINA</td></tr>
                    <tr className="tr"><td>7FDAf5adbf33ba9e...</td><td><span>6</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>334159.71 LINA</td></tr>
                    <tr className="tr"><td>679b41D2E987Ef93...</td><td><span>8</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>111386.57 LINA</td></tr>
                    <tr className="tr"><td>5FDaebc247Aee33a...</td><td><span>10</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>2281245.23 LINA</td></tr>
                    <tr className="tr"><td>efbc4567da4readc...</td><td><span>13</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>760415.07 LINA</td></tr>
                    <tr className="tr"><td>F4672dB458c621D2...</td><td><span>14</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>936734.45 LINA</td></tr>
                    <tr className="tr"><td>76ebLINAacccB4521...</td><td><span>16</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>312244.81 LINA</td></tr>
                    <tr className="tr"><td>ba9eCb5B69eCb5B6...</td><td><span>17</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>1024829.09 LINA</td></tr>
                    <tr className="tr"><td>5acf689b3ba9eCb5...</td><td><span>19</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>341609.69 LINA</td></tr>
                    <tr className="tr"><td>5dfae06Aee33ba9e...</td><td><span>22</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>725619.35 LINA</td></tr>
                    <tr className="tr"><td>4858fbce58e857c5...</td><td><span>25</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>241873.11 LINA</td></tr>
                    <tr className="tr"><td>4ac7EfebE989703b...</td><td><span>27</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>1054198.73 LINA</td></tr>
                    <tr className="tr"><td>46faecdba7Aee33a...</td><td><span>28</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">37479dcC23f65kilFF9eCb5...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>351399.57 LINA</td></tr>
                    <tr className="tr"><td>7da4r86dca4readc...</td><td><span>29</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>961834.85 LINA</td></tr>
                    <tr className="tr"><td>Cb5B6CB458c621D2...</td><td><span>31</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>320611.61 LINA</td></tr>
                    <tr className="tr"><td>9eCb5B6CB458c621...</td><td><span>33</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>0x73f6854aC17e300daC7...</td><td>2724387.19 LINA</td></tr>
                    <tr className="tr"><td>745ee33ba9eCb5B6...</td><td><span>35</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC7...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>908129.06 LINA</td></tr>
                    <tr className="tr"><td>3f5aAff33ba9eCb5...</td><td><span>37</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>LINA5B6CB458c621D2E9...</td><td>1547209.87 LINA</td></tr>
                    <tr className="tr"><td>eC23f56Aee33ba9e...</td><td><span>39</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">LINA5B6CB458c621D2E9...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>515736.62 LINA</td></tr>
                    <tr className="tr"><td>58c621D2E987Efeb...</td><td><span>41</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">0x73f6854aC17e300daC784...<span className="green">claimed</span></td><td>LINAfe47b271cb5CBAd5C...</td><td>2693145.48 LINA</td></tr>
                    <tr className="tr"><td>eC2eC23f47Aee33a...</td><td><span>44</span>&nbsp;<span>min</span>&nbsp;<span>ago</span></td><td className="tClass">83837fe47b271cb5CBAd5C...<span className="red">claimed</span></td><td>0x73f6854aC17e300...</td><td>897715.16 LINA</td></tr>
                </tbody>                                
            </table>

        </Fragment>
    )
}

export default TableRow;